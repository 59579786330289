define("discourse/plugins/swapd-global-bookmark/discourse/templates/connectors/topic-above-footer-buttons/swapd-bookmark-reminder-conn", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <GlobalBookmarkInfo @topic={{@outletArgs.model}}/>
  */
  {
    "id": "XaFpnvcE",
    "block": "[[[8,[39,0],null,[[\"@topic\"],[[30,1,[\"model\"]]]],null]],[\"@outletArgs\"],false,[\"global-bookmark-info\"]]",
    "moduleName": "discourse/plugins/swapd-global-bookmark/discourse/templates/connectors/topic-above-footer-buttons/swapd-bookmark-reminder-conn.hbs",
    "isStrictMode": false
  });
});