define("discourse/plugins/swapd-global-bookmark/discourse/initializers/swapd-bookmark", ["exports", "discourse/lib/plugin-api", "discourse/components/modal/bookmark", "discourse/lib/bookmark", "@glimmer/tracking"], function (_exports, _pluginApi, _bookmark, _bookmark2, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.BookmarkFormDataExtended = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  // create a BookmarkFormDataExtended that adds an 'enable_all_admins' data field
  // app/assets/javascripts/discourse/app/lib/bookmark.js
  let BookmarkFormDataExtended = _exports.BookmarkFormDataExtended = (_class = class BookmarkFormDataExtended extends _bookmark2.BookmarkFormData {
    constructor(bookmark) {
      super(bookmark);
      _initializerDefineProperty(this, "enableAllAdmins", _descriptor, this);
    }
    get saveData() {
      return {
        reminder_at: this.reminderAtISO,
        name: this.name,
        id: this.id,
        auto_delete_preference: this.autoDeletePreference,
        bookmarkable_id: this.bookmarkableId,
        bookmarkable_type: this.bookmarkableType,
        enable_all_admins: this.enableAllAdmins
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "enableAllAdmins", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
  function initPlugin(api) {
    const siteSettings = api.container.lookup("site-settings:main");
    const currentUser = api.getCurrentUser();
    if (!siteSettings.swapd_bookmark_enabled) {
      return;
    }

    // app/assets/javascripts/discourse/app/controllers/topic.js
    api.modifyClass("controller:topic", {
      pluginId: 'swapd-global-bookmark',
      _modifyTopicBookmark(bookmark) {
        this.modal.show(_bookmark.default, {
          model: {
            bookmark: new BookmarkFormDataExtended(bookmark),
            // only line that has been changed from original method
            afterSave: savedData => {
              this._syncBookmarks(savedData);
              this.model.set("bookmarking", false);
              this.model.set("bookmarked", true);
              this.model.incrementProperty("bookmarksWereChanged");
              this.appEvents.trigger("bookmarks:changed", savedData, bookmark.attachedTo());
            },
            afterDelete: (topicBookmarked, bookmarkId) => {
              this.model.removeBookmark(bookmarkId);
            }
          }
        });
      }
    });

    // app/assets/javascripts/discourse/app/components/modal/bookmark.js
    api.modifyClass("component:modal/bookmark", {
      pluginId: 'swapd-global-bookmark',
      get showAllAdminsCheckbox() {
        return this.currentUser && this.currentUser.can_global_bookmark && this.args.model.bookmark.bookmarkableType == 'Topic' && !this.args.model.bookmark.id;
      }
    });
  }
  var _default = _exports.default = {
    name: "swapd-bookmark",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.8", initPlugin);
    }
  };
});